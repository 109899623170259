<template>
  <div class="container-fluid">
    <div class="row">
      <div class="flex xs12">
        <h5>{{ formatted() }}</h5>
      </div>
      <hr>
    </div>
    <div class="row">
      <div class="flex xs12 md6">
        <va-input
          v-model="form.presentations.value"
          color="info"
          type="number"
          step="1"
          min="0"
          :disabled="loading"
          :label="$t('teams.inputs.presentationsNumberInput')"
          :error="!!form.presentations.errors.length"
          :error-messages="translatedErrors(form.presentations.errors)"
          @blur="validate('presentations')"
        />
      </div>
      <div class="flex xs12 md6">
        <va-input
          v-model="form.motherChurch.value"
          color="info"
          :disabled="loading"
          :label="$t('teams.inputs.motherChurchInput')"
          :error="!!form.motherChurch.errors.length"
          :error-messages="translatedErrors(form.motherChurch.errors)"
          @blur="validate('motherChurch')"
        />
      </div>
    </div>
    <div class="row">
      <div class="flex xs12 md6">
        <va-input
          v-model="form.preachingPoint.value"
          color="info"
          :disabled="loading"
          :label="$t('teams.inputs.preachingPointInput')"
          :error="!!form.preachingPoint.errors.length"
          :error-messages="translatedErrors(form.preachingPoint.errors)"
          @blur="validate('preachingPoint')"
        />
      </div>
      <div class="flex xs12 md6">
        <va-select
          v-model="selectedLeader"
          :label="$t('teams.inputs.preachingLeaderInput')"
          :options="leadersList"
          :loading="loading"
          :no-options-text="$t('layout.empty')"
          :disabled="loading"
          text-by="label"
          searchable
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { validatorMixin } from '@/services/validator'
import parse from 'date-fns/parse'

export default {
  name: 'monthly-plan',
  props: {
    leadersList: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    currentMonth: {
      type: String,
      required: true,
    },
    remoteData: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
  mixins: [validatorMixin],
  data () {
    return {
      slugger: Math.random().toString(),
      selectedLeader: '',
      form: {
        presentations: {
          value: 0,
          validate: {
            required: true,
          },
          errors: [],
        },
        motherChurch: {
          value: '',
          validate: {},
          errors: [],
        },
        preachingPoint: {
          value: '',
          validate: {},
          errors: [],
        },
      },
    }
  },
  computed: {
    ...mapGetters(['currentLocale']),
  },
  watch: {
    remoteData (val) {
      if (!val.id) return
      this.setFormData(val)
    },
    currentLocale (val) {
      this.validateAll()
    },
  },
  created () {
    this.$nextTick(() => {
      this.validateAll()
    })
  },
  methods: {
    getPlanning () {
      this.validateAll()
      if (!this.formReady) return

      const planning = this.getFormData({})
      if (this.selectedLeader) {
        planning.leaderName = this.selectedLeader.name
      }
      return planning
    },
    formatted () {
      if (!this.currentMonth) return ''
      const date = parse(this.currentMonth, 'MM/dd/yyyy', new Date())

      const formatted = this.$date.format(date, 'MMMM - yyyy')
      return this.capitalize(formatted)
    },
    capitalize (s) {
      return s.charAt(0).toUpperCase() + s.slice(1)
    },
  },
}
</script>
